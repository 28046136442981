import * as types from './actionTypes'
import {LIMIT, TIME_RANGE, VALUE_KEY, AUX_LIMITS, AUX_THRESHOLDS, DJANGO_URL, BLICKV_DATA_URL, MAX_DAYS} from './constants'




/*
 * action creators
 */

export function showDetail(location, currentDay) {
  return { type: types.SHOW_DETAIL, location: location, day: currentDay}
}

export function updateDetailDay(currentDay) {
  return { type: types.UPDATE_DETAIL_DAY, day: currentDay}
}


export function getData(stationID, stationName, spec, prod) {
  return (dispatch) => {
    const prodQuery = prod === 'surfc' ? 'trop' : prod;
    const fetchUrl = BLICKV_DATA_URL + stationID + '_' + spec + '_' + stationName + '_' + prodQuery + '.json';

    fetch(fetchUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch: ' + fetchUrl + ', Status: ' + response.status);
        }
        return response.json();
      })
      .then((json) => {
        return dispatch(getDataCallback(json, stationID, stationName, spec, prod));
      })
      .catch((error) => {
        console.log('Error fetching data from ' + fetchUrl + ':', error);
      });
  };
}


function getDataCallback(json, stationID, stationName, spec, prod) {
  var graphList = {};
  var graphs = [];
  var days = [];

  // Calculate the cutoff date (MAX_DAYS days ago)
  const today = new Date();
  const cutoffDate = new Date(today.setDate(today.getDate() - MAX_DAYS)); // MAX_DAYS days ago

  for (const gas in LIMIT[prod]) {
    const id = [stationID, spec, prod, gas].join('_');

    // Filter data and days
    const filteredData = {};
    const filteredDays = Object.keys(json[gas] || {}).filter(day => {
      const dayDate = new Date(day); // Convert "YYYY-MM-DD" to Date object
      if (dayDate >= cutoffDate) {
        // Keep data only for days within the last MAX_DAYS
        filteredData[day] = json[gas][day];
        return true;
      }
      return false;
    });

    graphList[id] = {
      params: {
        station: stationID,
        spec: spec,
        limit: LIMIT[prod][gas],
        timeRange: TIME_RANGE,
        prod: prod,
        gas: gas,
        valueKey: VALUE_KEY[prod],
        data: filteredData, // Filtered data
        days: filteredDays, // Filtered days
        auxData: {
          valueKey: [
            VALUE_KEY[prod] + '_uncert',
            'fitrms',
            'wlshift',
            'Tint',
            'FW1',
            'FW2',
          ],
          limits: AUX_LIMITS[gas],
          thresholds: AUX_THRESHOLDS[gas],
        },
      },
    };

    graphs.push(id);

    // Update the main days array with the latest filtered days
    if (days.length < filteredDays.length) days = filteredDays;
  }

  days.sort().reverse(); // Sort days in descending order
  const row = [[stationID, spec].join('_'), graphs];

  return { type: types.GET_DATA, row: row, graphList: graphList, days: days };
}


export function stationsInit(){
    return (dispatch) => {
    var list = {}
    fetch(DJANGO_URL + 'all_stations.json')
    .then((response) => response.json())
    .then((json) => {
      for (var s of json.data){
        list[s.panID] = {name: s.name, location: s.location}
    }
    return dispatch({type: types.STATIONS_INIT, stationList: list})
    })
  }
}

/*export function stationsInit(){
    var list = {}
    for (var s of all_stations.data){
        list[s.panID] = {name: s.name, location: s.location}
    }
    return {type: types.STATIONS_INIT, stationList: list}
}*/


export function selectStations(stations){
    return {type: types.SELECT_STATIONS, stations: stations}
}

export function selectGases(gases){
    return {type: types.SELECT_GASES, gases: gases}
}

export function selectGlobalDay(day){
    return {type: types.SELECT_GLOBAL_DAY, day: day}
}



 
